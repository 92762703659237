
function addZero(number: number) {
    if (number <= 9) {
        return "0" + number;
    }
    return number;
}
function millisecondsToDate(milliseconds: number): string {
    let objectDate = new Date(milliseconds);
    let day = addZero(objectDate.getDate());
    let month = addZero(objectDate.getMonth() + 1);
    let year = addZero(objectDate.getFullYear());
    let hours = addZero(objectDate.getHours());
    let minutes = addZero(objectDate.getMinutes());
    //day + "/" + month + "/" + year + ", " + hours + ":" + minutes
    return `${year}-${month}-${day}T${hours}:${minutes}`;
}
function milliseconsToItalianDate(milliseconds: number): string {
    let objectDate = new Date(milliseconds);
    let hours = addZero(objectDate.getHours());
    let minutes = addZero(objectDate.getMinutes());
    return milliseconsToItalianDateNoTime(milliseconds) + ", " + hours + ":" + minutes
}
function milliseconsToItalianDateNoTime(milliseconds: number): string {
    let objectDate = new Date(milliseconds);
    let day = addZero(objectDate.getDate());
    let month = addZero(objectDate.getMonth() + 1);
    let year = addZero(objectDate.getFullYear());
    return day + "/" + month + "/" + year;
}

function dateToMillisecond(date: string): number {
    var dateVar = new Date(date);
    return dateVar.getTime();
}

export { millisecondsToDate, dateToMillisecond, milliseconsToItalianDate, milliseconsToItalianDateNoTime };