import { DocumentSnapshot } from "firebase/firestore";

export enum TipoPurchase {
    PURCHASE = "PURCHASE",
    REDEEM = "REDEEM"
}
export enum StatoPurchase {
    PAID = "PAID",
    IN_PROGRESS = "IN_PROGRESS",
    COMPLETED = "COMPLETED"
}

export class Purchase {
    id: string;
    id_club: string;
    id_user: string;
    id_promotion: string;
    type: TipoPurchase;
    status: StatoPurchase;
    name: string;
    unit_price: number;
    total_price: number;
    quantity: number;
    remaining_quantity: number;
    datetime: number;
    scan_data: ScanData[];
    expiration_date?: number;

    constructor({
        id,
        id_club,
        id_user,
        id_promotion,
        type,
        status,
        name,
        unit_price,
        total_price,
        quantity,
        remaining_quantity,
        datetime,
        scan_data,
        expiration_date
    }: Partial<Purchase>) {
        this.id = id || '';
        this.id_club = id_club || '';
        this.id_user = id_user || '';
        this.id_promotion = id_promotion || '';
        this.type = TipoPurchase[type as keyof typeof TipoPurchase];
        this.status = StatoPurchase[status as keyof typeof StatoPurchase];
        this.name = name || '';
        this.unit_price = unit_price || 0;
        this.total_price = total_price || 0;
        this.quantity = quantity || 0;
        this.remaining_quantity = remaining_quantity || 0;
        this.datetime = datetime || 0;
        this.scan_data = scan_data || [];
        this.expiration_date = expiration_date;
    }

    static purchaseFromSnapshot(snapshot: DocumentSnapshot) {
        const data = snapshot.data()!;
        return new Purchase({
            id: data.id,
            id_club: data.id_club,
            id_promotion: data.id_promotion,
            id_user: data.id_user,
            type: TipoPurchase[data.type as keyof typeof TipoPurchase],
            status: StatoPurchase[data.status as keyof typeof StatoPurchase],
            name: data.name,
            unit_price: parseInt(data.unit_price),
            total_price: parseInt(data.total_price),
            quantity: parseInt(data.quantity),
            remaining_quantity: parseInt(data.remaining_quantity),
            datetime: data.datetime,
            scan_data: data.scan_data ? Purchase.deserializeScanData(data.scan_data) : [], //TODO fix
            expiration_date: data.expiration_date,
        });
    }
    serializePurchase(): any {
        const result: any = { ...this };
        result.scan_data = this.serializeScanData();
        return result;
    }

    serializeScanData(): any[] {
        const result: any[] = [];
        this.scan_data.forEach((scanData: ScanData) => result.push({ ...scanData }));
        return result;
    }

    static deserializeScanData(data: any): ScanData[] {
        const result: ScanData[] = [];
        for (let i = 0; i < data.length; i++) {
            result.push(new ScanData({
                id_qr_code: data[i]['id_qr_code'],
                scanned_date: data[i]['scanned_date'],
                scanned_quantity: data[i]['scanned_quantity']
            }));
        };
        return result;
    }
}

export class ScanData {
    id_qr_code: string;
    scanned_date: number;
    scanned_quantity: number;

    constructor({ id_qr_code, scanned_date, scanned_quantity }: Partial<ScanData>) {
        this.id_qr_code = id_qr_code || "";
        this.scanned_date = scanned_date || 0;
        this.scanned_quantity = scanned_quantity || 0;
    }
}