import React, { useEffect, useState, useContext } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, Link } from "react-router-dom";
import "./Dashboard.css";
import { auth, loadDbData } from "./utils/firebase";
import Header from "./utils/Header";
import AppContext from './AppContext';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import { Purchase } from "./models/ModelPurchase";


function Dashboard() {
  const dbContext = useContext(AppContext);


  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("name");
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");

    setName(user?.displayName ?? "");
    if (!dbContext.firstRead ?? false) {
      loadDbData(dbContext);
      dbContext.setFirstRead(true);
    }
  }, [user, loading]);

  return (
    <div>
      <Header />
      <Container fluid="lg">
        <div className="dash-container">
          <Row className="dash-title">
            Dashboard
          </Row>
          <Row style={{ width: "100%" }}>
            <div className="container-numeri">
              <div className="box-numeri">
                <div className="numero">{dbContext.users?.length}</div>
                <div className="subtitle-numero">Utenti</div>
              </div>
              <div className="box-numeri">
                <div className="numero">{dbContext.purchases?.length}</div>
                <div className="subtitle-numero">Transazioni</div>
              </div>
              <div className="box-numeri">
                <div className="numero">{dbContext.purchases?.
                  filter((pur: Purchase) => pur.type == "PURCHASE" && pur.total_price != undefined)
                  .reduce((accumulator: number, object: Purchase) => {
                    return accumulator + (object.total_price / 100);
                  }, 0)}€</div>
                <div className="subtitle-numero">Totale transato</div>
              </div>
            </div>
          </Row>
          <Row style={{ width: "100%" }}>
            <div className="box-clubs">
              <div className="numero">{dbContext.clubs?.length}</div>
              <div className="subtitle-numero">Clubs</div>
              <div className="club-logo-container"></div>
            </div>
          </Row>
        </div>
      </Container >
    </div >

  );
}

export default Dashboard;
