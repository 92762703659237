import React, { useEffect, useState, useContext } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, Link, useParams } from "react-router-dom";
import { auth, loadDbData, createAuthUser, sendPasswordReset, db } from "../utils/firebase";
import { User } from "firebase/auth";
import Header from "../utils/Header";
import AppContext from '../AppContext';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import PurchasesList from "../purchases/PurchasesList";
import { ClubDrinxPoint, Utente } from "../models/ModelUtente";
import { Club } from "../models/ModelClub";
import { UserModal } from "./UserModal";
require("./UserInfo.css");


var newUserData: Utente;


function UserInfo() {
    const dbContext = useContext(AppContext);

    const [user, loading, error] = useAuthState(auth);
    const [userData, setUserData] = useState(newUserData);
    const [loadedData, setLoadedData] = useState(false);
    const [utenteModal, setUtenteModal] = useState<Utente | undefined>(undefined);

    const navigate = useNavigate();


    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/");
        if (!dbContext.firstRead) {
            loadDbData(dbContext);
            dbContext.setFirstRead(true);
        }
        if (!loadedData) {
            setUserData(dbContext?.users?.find((user: Utente) => user.id == params.id_user) || newUserData);
            if (typeof userData?.id != "undefined") {
                setLoadedData(true);
            }
        }
    }, [user, loading, dbContext]);

    const params = useParams();

    return (
        <div>
            <Header />
            <Container fluid="lg">
                <Row className="users-title vertical-padding" >
                    <Col style={{ textAlign: "left" }}>{userData?.name + " " + userData?.last_name}</Col>

                </Row>
                <Row>
                    <Col md lg="4">
                        <div className="user-info-column">
                            <p onClick={() => setUtenteModal(userData)} className="user-edit-button">Modifica</p>
                            <p className="user-data-title">Dati utente</p>
                            <p className="user-data-label">Nome</p>
                            <p className="user-data-text">{userData?.name}</p>
                            <p className="user-data-label">Cognome</p>
                            <p className="user-data-text">{userData?.last_name}</p>
                            <p className="user-data-label">Email</p>
                            <p className="user-data-text">{userData?.email}</p>
                            <p className="user-data-label">N° telefono</p>
                            <p className="user-data-text">{userData?.telephone}</p>
                            <p className="user-data-label">Password</p>
                            <p className="user-data-link" onClick={() => sendPasswordReset(userData?.email)}>Reset Password</p>

                            <hr className="divider" />
                            <div className="user-id-field"><b>Id:</b> {userData?.id}</div>
                            <div className="user-id-field"><b>Stripe:</b>  <Link hidden={!userData?.id_stripe} target="_blank" to={"https://dashboard.stripe.com/test/customers/" + userData?.id_stripe}>{userData?.id_stripe}</Link></div>
                        </div>
                    </Col>
                    <Col>
                        <Row className="vertical-padding" >
                            <Col style={{ textAlign: "left" }} className="user-data-title">Utilizzo DRINX</Col>

                        </Row>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th className="drinx-table-label">Club</th>
                                    <th className="drinx-table-label">Totale DRINX accumulati</th>
                                    <th className="drinx-table-label">Totale DRINX rimanenti</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    userData && userData.drinx &&  Array.from( userData?.drinx ).map(([key, value]) => {
                                        const club: Club = dbContext?.clubs?.find((club: Club) => club.id == key);
                                        return (
                                            <tr key={key}>
                                                <th><img src={club?.image_url} key={club?.image_url} className="clubInfoImgSmall" alt="Club Logo" hidden={!club?.image_url || club?.image_url == ""} /></th>
                                                <th className="drinx-table-club"><Link to={"/clubs/" + key}>{club?.name}</Link></th>
                                                <th className="drinx-table-points">{value?.total_points_accrued}</th>
                                                <th className="drinx-table-points">{value?.current_points}</th>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </Table>
                        <Row className="vertical-padding" >
                            <Col style={{ textAlign: "left" }} className="user-data-title">Transazioni</Col>
                            <PurchasesList id_user={params.id_user} />
                        </Row>
                    </Col>
                </Row>
            </Container>
            <UserModal
                show={utenteModal != undefined}
                onHide={() => {
                    setUtenteModal(undefined);
                    setUserData(dbContext?.users?.find((user: Utente) => user.id == params.id_user) || newUserData);
                }}
                utente={utenteModal}
                dbContext={dbContext}
            />
        </div>
    );
}

export default UserInfo;
