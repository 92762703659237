import React, { useEffect, useState, useContext } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, Link } from "react-router-dom";
import { auth, loadDbData } from "../utils/firebase";
import Header from "../utils/Header";
import AppContext from '../AppContext';
import PurchasesList from "./PurchasesList";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { Purchase } from "../models/ModelPurchase";
require("./Purchases.css");



function Purchases() {
  const dbContext = useContext(AppContext);

  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("name");
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");

    setName(user?.displayName ?? "");
    if (!dbContext.firstRead) {
      loadDbData(dbContext);
      dbContext.setFirstRead(true);
    }
  }, [user, loading]);

  return (
    <div>
      <Header />
      <Container fluid="lg" >
        <Row className="purchase-title vertical-padding" >
          <Col style={{ textAlign: "left" }}>Tutte le transazioni</Col>
          <Col xs lg="2">
            <div className="number-purchase">
              {dbContext.purchases?.
                filter((pur: Purchase) => pur.type == "PURCHASE" && pur.total_price != undefined)
                .reduce((accumulator: number, object: Purchase) => {
                  return accumulator + (object.total_price / 100);
                }, 0)}€
            </div>
            <div className="number-purchase-subtitle">
              Totale acquisti
            </div>
          </Col>
          <Col xs lg="2">
            <div className="number-purchase">
              {dbContext.purchases?.
                filter((pur: Purchase) => pur.type == "REDEEM" && pur.total_price != undefined)
                .reduce((accumulator: number, object: Purchase) => {
                  return accumulator + (object.total_price);
                }, 0)}
            </div>
            <div className="number-purchase-subtitle">
              DRINX usati
            </div>
          </Col>
        </Row>
        <Row className="vertical-padding">
          <PurchasesList
          />
        </Row>
      </Container>
    </div>

  );
}

export default Purchases;
