import React, { useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import { Promotion } from '../models/ModelClub';
import { dateToMillisecond, millisecondsToDate } from '../utils/utilities';
import { saveClub, savePromotion, saveUtente } from '../utils/firebase';
import { TipoPurchase } from '../models/ModelPurchase';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';
import { TipoUtente } from '../models/ModelUtente';

export function ClubModal(props: any) {
  const [club, setClub] = useState(props.club);
  const [address, setAddress] = useState(props.club?.address);
  const fileInputRef = useRef<HTMLInputElement | null>(null);


  function onInputChange(e: React.ChangeEvent<any>): void {
    const tmpClub = { ...club }; // Crea una copia di promotion
    tmpClub[e.target.name] = e.target.value; // Aggiorna la copia
    setClub(tmpClub);
  }
  function onInputAddressChange(e: React.ChangeEvent<any>): void {
    const tmpAddress = { ...address }; // Crea una copia di promotion
    tmpAddress[e.target.name] = e.target.value; // Aggiorna la copia
    setAddress(tmpAddress);
  }

  async function saveClubToDB() {
    console.log("PREPARE TO SAVE CLUB ")
    const tmpClub = { ...club };
    tmpClub.address = { ...address };
    await saveClub(tmpClub, props.dbContext);
    console.log("SAVE CLUB CONCLUDED");
    props.onHide();
  }

  function loadImage(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result
      //.replace('data:', '')
      //.replace(/^.+,/, '');
      const newClubData = { ...club };
      newClubData.image_url = base64String;
      setClub(newClubData);
    };
    reader.readAsDataURL(file);
  }

  useEffect(() => {
    if (props.club != undefined) {
      setClub(props.club);
      setAddress(props.club.address);
    }
  }, [props.club]);

  const handleClick = () => {
    // Simula il clic sull'input
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <Modal aria-labelledby="contained-modal-title-vcenter"
      {...props}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Aggiungi o modifica un club
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="grid-example">
        <Container>
          <Row className='riga-input-modal'>
            <div className='modal-field modal-image-field'>
              <img src={club?.image_url} style={{ marginBottom: "10px", maxHeight: "200px", maxWidth: "400px" }} alt="Immagine" key={club?.image_url}/>
              <input ref={fileInputRef} hidden={true} type="file" name="image_url" accept="image/png, image/jpeg" className="primary-button" onChange={e => loadImage(e)} />
              <div className='primary-button' onClick={() => {
                if (fileInputRef.current) {
                  fileInputRef.current.click();
                }
              }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                  <path d="M15 9.75H9.75V15H8.25V9.75H3V8.25H8.25V3H9.75V8.25H15V9.75Z" fill="#C8236F" />
                </svg>
                {club?.image_url != undefined && club?.image_url != "" ? "Modifica" : "Aggiungi"}
              </div>
            </div>
          </Row>
          <Row className='riga-input-modal'>
            <div className='modal-label'>
              Nome
            </div>
            <div className='modal-field'>
              <input className="modal-input" name='name' value={club?.name} onChange={(e) => onInputChange(e)}></input>
            </div>
          </Row>
          <Row className='riga-input-modal'>
            <div className='modal-label'>
              Ragione Sociale
            </div>
            <div className='modal-field'>
              <input className="modal-input" name='social_reason' value={club?.social_reason} onChange={(e) => onInputChange(e)}></input>
            </div>
          </Row>
          <Row className='riga-input-modal'>
            <div className='modal-label'>
              Email
            </div>
            <div className='modal-field'>
              <input className="modal-input" name='email' value={club?.email} onChange={(e) => onInputChange(e)} disabled={club && club?.id != undefined && club?.id != ""}></input>
            </div>
          </Row>
          <Row className='riga-input-modal'>
            <div className='modal-label'>
              Telefono
            </div>
            <div className='modal-field'>
              <input className="modal-input" name='telephone' value={club?.telephone} onChange={(e) => onInputChange(e)}></input>
            </div>
          </Row>

          <Row className='riga-input-modal'>
            <div className='modal-label'>
              Via (indirizzo)
            </div>
            <div className='modal-field'>
              <input className="modal-input" name='street' value={address?.street} onChange={(e) => onInputAddressChange(e)}></input>
            </div>
          </Row>
          <Row className='riga-input-modal'>
            <div className='modal-label'>
              Provincia
            </div>
            <div className='modal-field'>
              <input className="modal-input" name='province' value={address?.province} onChange={(e) => onInputAddressChange(e)}></input>
            </div>
          </Row>
          <Row className='riga-input-modal'>
            <div className='modal-label'>
              Città
            </div>
            <div className='modal-field'>
              <input className="modal-input" name='city' value={address?.city} onChange={(e) => onInputAddressChange(e)}></input>
            </div>
          </Row>
          <Row className='riga-input-modal'>
            <div className='modal-label'>
              Cap
            </div>
            <div className='modal-field'>
              <input className="modal-input" name='cap' value={address?.cap} onChange={(e) => onInputAddressChange(e)}></input>
            </div>
          </Row>

          <Row className='riga-input-modal'>
            <div className='modal-label'>
              Stato
            </div>
            <div className='modal-field'>
              <input className="modal-input" name='country' value={address?.country} onChange={(e) => onInputAddressChange(e)}></input>
            </div>
          </Row>

        </Container>
      </Modal.Body>
      <Modal.Footer>
        <div onClick={props.onHide} className="secondary-button">Annulla</div>
        <div onClick={() => saveClubToDB()} className="primary-button">Salva</div>
      </Modal.Footer>
    </Modal>
  );
}
