import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import { Promotion } from '../models/ModelClub';
import { dateToMillisecond, millisecondsToDate } from '../utils/utilities';
import { savePromotion, saveUtente } from '../utils/firebase';
import { TipoPurchase } from '../models/ModelPurchase';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';
import { TipoUtente } from '../models/ModelUtente';

export function UserModal(props: any) {
  const [utente, setUtente] = useState(props.utente);

  function onInputChange(e: React.ChangeEvent<any>): void {
    const tmpUtente = { ...utente }; // Crea una copia di promotion
    tmpUtente[e.target.name] = e.target.value; // Aggiorna la copia
    setUtente(tmpUtente);
  }

  async function saveUtenteToDB() {
    console.log("PREPARE TO SAVE UTENTE ")
    const tmpUtente = { ...utente };
    if (tmpUtente.type == undefined) tmpUtente.type = TipoUtente.USER;
    await saveUtente(tmpUtente, props.dbContext, props.user);
    console.log("SAVE UTENTE CONCLUDED");
    props.onHide();
  }

  useEffect(() => {
    if (props.utente != undefined) setUtente(props.utente);
  }, [props.utente]);

  return (
    <Modal aria-labelledby="contained-modal-title-vcenter"
      {...props}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Aggiungi o modifica un utente
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="grid-example">
        <Container>
          <Row className='riga-input-modal'>
            <div className='modal-label'>
              Nome
            </div>
            <div className='modal-field'>
              <input className="modal-input" name='name' value={utente?.name} onChange={(e) => onInputChange(e)}></input>
            </div>
          </Row>
          <Row className='riga-input-modal'>
            <div className='modal-label'>
              Cognome
            </div>
            <div className='modal-field'>
              <input className="modal-input" name='last_name' value={utente?.last_name} onChange={(e) => onInputChange(e)}></input>
            </div>
          </Row>
          <Row className='riga-input-modal'>
            <div className='modal-label'>
              Email
            </div>
            <div className='modal-field'>
              <input className="modal-input" name='email' value={utente?.email} onChange={(e) => onInputChange(e)} disabled={utente && utente?.id != undefined && utente?.id != ""}></input>
            </div>
          </Row>
          <Row className='riga-input-modal'>
            <div className='modal-label'>
              Telefono
            </div>
            <div className='modal-field'>
              <input className="modal-input" name='telephone' value={utente?.telephone} onChange={(e) => onInputChange(e)}></input>
            </div>
          </Row>
          <Row className='riga-input-modal' hidden={utente && utente?.id != undefined && utente?.id != ""}>
            <div className='modal-label'>
              Password
            </div>
            <div className='modal-field'>
              <input className="modal-input" name='password' value={utente?.password} onChange={(e) => onInputChange(e)}></input>
            </div>
          </Row>
          
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <div onClick={props.onHide} className="secondary-button">Annulla</div>
        <div onClick={() => saveUtenteToDB()} className="primary-button">Salva</div>
      </Modal.Footer>
    </Modal>
  );
}
