import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import AppContext from './AppContext';
import Users from './users/Users';
import UserInfo from './users/UserInfo';
import Clubs from './clubs/Clubs';
import ClubInfo from './clubs/ClubInfo';
import Purchases from './purchases/Purchases';
import Login from './auth/Login';
import Reset from './auth/Reset';
import Dashboard from './Dashboard';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';



function App() {
  const [users, setUsers] = useState([]);
  const [clubs, setClubs] = useState([]);
  const [purchases, setPurchases] = useState([]);
  const [firstRead, setFirstRead] = useState(false);
  const [currentUser, setCurrentUser] = useState({});

  return (
    <AppContext.Provider value={{
      users, setUsers,
      clubs, setClubs,
      purchases, setPurchases,
      firstRead, setFirstRead,
      currentUser, setCurrentUser
    }}>
      <link rel="stylesheet"
        href="https://fonts.googleapis.com/css" />
      <div className="app">
        <Router>
          <Routes>
            <Route path="/users" element={<Users />} />
            <Route path="/users/:id_user" element={<UserInfo />} />
            <Route path="/clubs" element={<Clubs />} />
            <Route path="/clubs/:id_club" element={<ClubInfo />} />
            <Route path="/purchases" element={<Purchases />} />
            <Route path="/" element={<Login />} />
            {/* <Route exact path="/register" element={<Register />} /> */}
            <Route path="/reset" element={<Reset />} />
            <Route path="/dashboard" element={<Dashboard />} />
          </Routes>
        </Router>
      </div>
    </AppContext.Provider >

  );
}


export default App;
