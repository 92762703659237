import { initializeApp } from "firebase/app";
import { useNavigate } from "react-router-dom";

import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  createUserWithEmailAndPassword,
  User
} from "firebase/auth";
import {
  getFirestore,
  collection,
  query, getDocs, doc, where, setDoc, addDoc, deleteDoc, getDoc, orderBy, Query, Firestore, FirestoreSettings, DocumentSnapshot, QuerySnapshot
} from "firebase/firestore";
import { millisecondsToDate, dateToMillisecond } from "./utilities";
import { v4 as uuidv4 } from 'uuid';
import { Utente } from "../models/ModelUtente";
import { Context } from "vm";
import { Club, Promotion } from "../models/ModelClub";
import axios, { AxiosResponse } from "axios";
import { Purchase } from "../models/ModelPurchase";

const env = {
  SAVE_USER: "https://saveUser-yz6saf3z3q-uc.a.run.app",
  SEND_AUTH_MAIL: "https://sendauthmail-yz6saf3z3q-uc.a.run.app",
  STRIPE_CREATE_INTENT: "https://createintent-yz6saf3z3q-uc.a.run.app",
  STRIPE_CHECK_PAYMENT: "https://checkpayment-yz6saf3z3q-uc.a.run.app",
  REDEEM_PURCHASE: "https://redeempurchase-yz6saf3z3q-uc.a.run.app",
  CONSUME_PURCHASE: "https://consumePurchase-yz6saf3z3q-uc.a.run.app",

  // SAVE_USER: "http://127.0.0.1:5001/drinx-app-ad949/us-central1/saveUser",
  // SEND_AUTH_MAIL: "http://127.0.0.1:5001/drinx-app-ad949/us-central1/sendAuthMail",
  // STRIPE_CREATE_INTENT: "http://127.0.0.1:5001/drinx-app-ad949/us-central1/createIntent",
  // STRIPE_CHECK_PAYMENT: "http://127.0.0.1:5001/drinx-app-ad949/us-central1/checkPayment",
  // REDEEM_PURCHASE: "http://127.0.0.1:5001/drinx-app-ad949/us-central1/redeemPurchase",
  // CONSUME_PURCHASE: "http://127.0.0.1:5001/drinx-app-ad949/us-central1/consumePurchase",
}

const firebaseConfig = {
  apiKey: "AIzaSyDdnIE2H1u29gQ9Nb9BAQp1tXQc1ybBoW4",
  authDomain: "drinx-app-ad949.firebaseapp.com",
  projectId: "drinx-app-ad949",
  storageBucket: "drinx-app-ad949.appspot.com",
  messagingSenderId: "946237090133",
  appId: "1:946237090133:web:e290a53e9c56293a8a27dc",
  measurementId: "G-RW3Z9LDHGN"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const db: Firestore = getFirestore(app);
const settings: FirestoreSettings = {
  ignoreUndefinedProperties: true
};
//setFirestoreSettings(db, settings);

// const googleProvider = new GoogleAuthProvider();

// const signInWithGoogle = async () => {
//   try {
//     const res = await signInWithPopup(auth, googleProvider);
//     const user = res.user;
//     const q = query(collection(db, "users"), where("uid", "==", user.uid));
//     const docs = await getDocs(q);
//     if (docs.docs.length === 0) {
//       await addDoc(collection(db, "users"), {
//         uid: user.uid,
//         name: user.displayName,
//         authProvider: "google",
//         email: user.email,
//       });
//     }
//   } catch (err) {
//     console.error(err);
//     alert(err.message);
//   }
// };

const logInWithEmailAndPassword = async (email: string, password: string) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err: any) {
    console.error(err);
    alert(err.message);
  }
};

// const registerWithEmailAndPassword = async (name, email, password) => {
//   try {
//     const res = await createUserWithEmailAndPassword(auth, email, password);
//     const user = res.user;
//     await addDoc(collection(db, "users"), {
//       uid: user.uid,
//       name,
//       authProvider: "local",
//       email,
//     });
//   } catch (err) {
//     console.error(err);
//     alert(err.message);
//   }
// };

const sendPasswordReset = async (email: string) => {
  return sendAuthMail(email, "resetPassword")
}
const sendVerifyMail = async (email: string) => {
  return sendAuthMail(email, "verifyEmail")
}
const sendAuthMail = async (email: string, mode: string) => {
  try {
    const res = await axios.get(env.SEND_AUTH_MAIL + `?mode=${mode}&userEmail=${email}`, {
      headers: { "Access-Control-Allow-Origin": "*" }
    });
    //await sendPasswordResetEmail(auth, email);
    alert("Mail inviata!");
  } catch (err: any) {
    console.error(err);
    alert(err.message + " while sending verification email");
  }
};

const createAuthUser = async (user: User, body: Utente) => {
  try {
    const idToken = await user.getIdToken(true);
    const res = await axios.post(env.SAVE_USER, body.serializeUtente(), {
      headers: {
        'Authorization': 'Bearer ' + idToken
      }
    });
    //await sendPasswordResetEmail(auth, email);
    console.log("Utente creato con successo");
    return res.data;
  } catch (err: any) {
    console.error(err);
    alert(err.response.data.message + " while creating user authentication");
    return false
  }
};

const logout = () => {
  signOut(auth);
};

const getUserById = async (id: string) => {
  try {
    const docRef = doc(db, "Utenti", id);
    const userDB = await getDoc(docRef);
    return Utente.utenteFromSnapshot(userDB);
  } catch (err) {
    console.error(err);
    alert("An error occured while fetching use info");
    return {};
  }
}

const saveUtente = async (userData: any, dbContext: Context, user: User) => {
  try {
    const esistente: Boolean = userData && userData.id != undefined && userData.id != "";

    const idToken = await user.getIdToken(true);
    const res: AxiosResponse = await axios.post(env.SAVE_USER, userData, {
      headers: {
        'Authorization': 'Bearer ' + idToken
      }
    });
    //await sendPasswordResetEmail(auth, email);
    console.log("Utente creato con successo");
    const savedUtente: Utente = Utente.deserializeUtente(res.data);

    const newUsers: Utente[] = dbContext.users;
    if (esistente) {
      const index = newUsers.findIndex(((obj: Utente) => obj.id === userData.id));
      newUsers[index] = savedUtente;
    } else {
      newUsers.unshift(savedUtente);
    }
    dbContext.setClubs(newUsers);
    return true;
  } catch (err) {
    console.error(err);
    alert("An error occured while saving user");
    return false;
  }
}

const getClubById = async (id: string) => {
  try {
    const docRef = doc(db, "Club", id);
    const userDB = await getDoc(docRef);
    return Club.clubFromSnapshot(userDB);
  } catch (err) {
    console.error(err);
    alert("An error occured while fetching use info");
    return {};
  }
}

const saveClub = async (clubData: Club, dbContext: Context) => {
  try {
    const newClubs = dbContext.clubs;
    const index = newClubs.findIndex(((obj: Club) => obj.id === clubData.id));
    const docToBeSaved = JSON.parse(JSON.stringify(clubData));
    // docToBeSaved.promotions = docToBeSaved.promotions.map((prom: Promotion) => {
    //   if (prom.id == undefined) {
    //     prom.id = uuidv4();
    //   }
    //   prom.unit_price = prom.unit_price * 100;
    //   prom.insertion_date = dateToMillisecond(prom.insertion_date as string);
    //   prom.ending_date = dateToMillisecond(prom.ending_date as string);
    //   return prom;
    // });
    if (index < 0) {
      const collectionRef = collection(db, "Club");
      const res = await addDoc(collectionRef, docToBeSaved);
      clubData.id = res.id;
      newClubs.push(clubData);
      dbContext.setClubs(newClubs);
      return clubData;
    } else {
      const docRef = doc(db, "Club", docToBeSaved.id);
      const res = await setDoc(docRef, docToBeSaved);
      newClubs[index] = clubData;
      dbContext.setClubs(newClubs);
    }
    return true;
  } catch (err) {
    console.error(err);
    alert("An error occured while saving club");
    return false;
  }
}

const savePromotion = async (promotion: any, idClub: string, dbContext: Context) => {
  try {
    const esistente: Boolean = promotion && promotion.id != undefined && promotion.id != "";
    if (!esistente) promotion.id = uuidv4();
    const indexClub: number = dbContext.clubs.findIndex((club: Club) => club.id == idClub);
    const club: Club = dbContext.clubs[indexClub];
    if (!esistente) {
      club.promotions.unshift(Promotion.deserializePromotion(promotion));
    } else {
      const indexToUpdate = club.promotions.findIndex(prom => prom.id === promotion.id);
      club.promotions[indexToUpdate] = Promotion.deserializePromotion(promotion);
    }
    const docRef = doc(db, "Club", idClub);
    const res = await setDoc(docRef, JSON.parse(JSON.stringify(club.serializeClub())));
    const clubs: Club[] = dbContext.clubs;
    clubs[indexClub] = club;
    dbContext.setClubs(clubs);

    return true;
  } catch (err) {
    console.error(err);
    alert("An error occured while saving club");
    return false;
  }
}
const deletePromotion = async (promotion: any, idClub: string, dbContext: Context) => {
  try {
    const indexClub: number = dbContext.clubs.findIndex((club: Club) => club.id == idClub);
    const club: Club = dbContext.clubs[indexClub];
    club.promotions = club.promotions.filter((prom: Promotion) => prom.id != promotion.id);
    const docRef = doc(db, "Club", idClub);
    const res = await setDoc(docRef, JSON.parse(JSON.stringify(club.serializeClub())));
    const clubs: Club[] = dbContext.clubs;
    clubs[indexClub] = club;
    dbContext.setClubs(clubs);

    return true;
  } catch (err) {
    console.error(err);
    alert("An error occured while saving club");
    return false;
  }
}

const loadDbData = async (dbContext: Context) => {
  try {
    var collectionQuery = collection(db, 'Utenti');
    var result: QuerySnapshot = await getDocs(collectionQuery);
    await dbContext.setUsers(
      result.docs.map((user: DocumentSnapshot) => {
        const ret = Utente.utenteFromSnapshot(user);
        return ret;
      })
    );

    collectionQuery = collection(db, 'Club');
    result = (await getDocs(collectionQuery));
    await dbContext.setClubs(
      result.docs.map(doc => {
        const ret = Club.clubFromSnapshot(doc);

        // const ret = doc.data();
        // const promotions = ret?.promotions.map((prom: Promotion) => {
        //   prom.unit_price = prom.unit_price / 100;
        //   prom.insertion_date = parseInt(millisecondsToDate(prom.insertion_date));
        //   prom.ending_date = parseInt(millisecondsToDate(prom.ending_date));
        //   return prom
        // });
        // ret.promotions = promotions;
        // ret.id = doc.id;
        return ret;
      })
    );

    collectionQuery = collection(db, 'Purchase');
    result = await getDocs(query(collectionQuery, orderBy("datetime", "desc")));
    await dbContext.setPurchases(
      result.docs.map(purhcase => {
        const ret: Purchase = Purchase.purchaseFromSnapshot(purhcase);
        return ret;
      })
    );
    console.log("LOADED INITIAL DATA")
  } catch (err) {
    console.error(err);
    alert("An error occured while fetching user data");
  }
}

export {
  auth,
  db,
  // signInWithGoogle,
  logInWithEmailAndPassword,
  // registerWithEmailAndPassword,
  sendPasswordReset,
  sendVerifyMail,
  logout,
  loadDbData,
  deletePromotion,
  savePromotion,
  saveClub,
  saveUtente,
  createAuthUser,
  getUserById
};
