import { DocumentSnapshot } from "firebase/firestore";
import { TipoPurchase } from "./ModelPurchase";

export class Club {
    id: string;
    address: Address;
    email: string;
    image_url: string;
    latitude: string;
    longitude: string;
    name: string;
    social_reason: string;
    telephone: string;
    promotions: Promotion[];
    credit: Credit;

    constructor({
        id,
        address,
        email,
        image_url,
        latitude,
        longitude,
        name,
        social_reason,
        telephone,
        promotions,
        credit,
    }: Partial<Club>) {
        this.id = id || '';
        this.address = address || new Address({ street: "", city: "", cap: "", province: "" });
        this.email = email || '';
        this.image_url = image_url || '';
        this.latitude = latitude || '';
        this.longitude = longitude || '';
        this.name = name || '';
        this.social_reason = social_reason || '';
        this.telephone = telephone || '';
        this.promotions = promotions || [];
        this.credit = credit || new Credit({ active: false, load_time: 0, points: 0 });
    }

    static clubFromSnapshot(snapshot: DocumentSnapshot) {
        const data = snapshot.data()!;
        return new Club({
            id: data.id,
            address: new Address({ street: data.address.street, city: data.address.city, cap: data.address.cap, province: data.address.province, country: data.address.country }),
            email: data.email,
            image_url: data.image_url,
            latitude: data.latitude,
            longitude: data.longitude,
            name: data.name,
            social_reason: data.social_reason,
            telephone: data.telephone,
            promotions: data.promotions ? Club.deserializePromotionList(data.promotions) : [],
            credit: data.credit ?
                new Credit({ active: data.credit.active, load_time: data.credit.load_time, points: data.credit.points }) :
                new Credit({ active: false, load_time: 0, points: 0 }),
        });
    }
    serializeClub(): any {
        const result: any = { ...this };
        result.promotions = this.promotions.map((prom: Promotion) => prom.serializePromotion());
        return result;
    }

    static deserializePromotionList(data: any): Promotion[] {
        const result: Promotion[] = [];
        for (let i = 0; i < data.length; i++) {
            result.push(Promotion.deserializePromotion(data[i]));
        };
        return result;
    }
}

export class Promotion {
    id: string;
    name: string;
    description: string;
    insertion_date: number;
    ending_date: number;
    type: TipoPurchase;
    unit_price: number;

    constructor({
        id,
        name,
        description,
        insertion_date,
        ending_date,
        type,
        unit_price
    }: Partial<Promotion>) {
        this.id = id || '';
        this.name = name || '';
        this.description = description || '';
        this.insertion_date = insertion_date || 0;
        this.ending_date = ending_date || 0;
        this.type = TipoPurchase[type as keyof typeof TipoPurchase];
        this.unit_price = unit_price || 0;
    }
    public static createEmptyPromotion(): Promotion {
        return new Promotion({});
    }
    static deserializePromotion(data: any): Promotion {
        return new Promotion({
            id: data['id'],
            name: data['name'],
            description: data['description'],
            insertion_date: data['insertion_date'],
            ending_date: data['ending_date'],
            type: TipoPurchase[data['type'] as keyof typeof TipoPurchase],
            unit_price: data['unit_price'],
        });
    }
    serializePromotion(): any {
        return { ...this };
    }
}

export class Address {
    city: string;
    province: string;
    cap: string;
    street: string;
    country: string;

    constructor({
        city,
        province,
        cap,
        street,
        country
    }: Partial<Address>) {
        this.city = city || '';
        this.province = province || '';
        this.cap = cap || '';
        this.street = street || '';
        this.country = country || '';
    }
}

export class Credit {
    active: boolean;
    load_time: number;
    points: number;

    constructor({
        active,
        load_time,
        points,
    }: Partial<Credit>) {
        this.active = active || false;
        this.load_time = load_time || 0;
        this.points = points || 0;
    }
}