import React, { useEffect, useState, useContext } from "react";
import AppContext from '../AppContext';
import { Link, useNavigate } from "react-router-dom";
import { auth, logInWithEmailAndPassword, getUserById, logout } from "../utils/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import "./Login.css";

function Login() {
  const dbContext = useContext(AppContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  const manageLogin = async () => {
    const userData = await getUserById(user?.uid);
    if (userData.type == "ADMIN") {
      dbContext.setCurrentUser(userData);
      navigate("/dashboard");
    } else {
      alert("Utente " + user.displayName + " non abilitato all'accesso alla dashboard");
      logout();
    }
  }
  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) {
      manageLogin();
    };
  }, [user, loading]);

  return (
    <div className="login">
      <div className="login__container">
        <input
          type="text"
          className="login__textBox"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-mail"
        />
        <input
          type="password"
          className="login__textBox"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        <button
          className="login__btn"
          onClick={() => logInWithEmailAndPassword(email, password)}
        >
          Login
        </button>
        {/* <button className="login__btn login__google" onClick={signInWithGoogle}>
          Login with Google
        </button> */}
        <div>
          <Link to="/reset">Password dimenticata</Link>
        </div>
        {/* <div>
          Don't have an account? <Link to="/register">Register</Link> now.
        </div> */}
      </div>
    </div>
  );
}

export default Login;
