import { DocumentSnapshot } from "firebase/firestore";

export enum TipoUtente {
    CLUB = "CLUB",
    CLUB_OPERATOR = "CLUB_OPERATOR",
    CLUB_MANAGER = "CLUB_MANAGER",
    USER = "USER",
    ADMIN = "ADMIN"
}

export class ClubDrinxPoint {
    current_points: number;
    total_points_accrued: number;

    constructor({ current_points, total_points_accrued, }: Partial<ClubDrinxPoint>) {
        this.current_points = current_points || 0;
        this.total_points_accrued = total_points_accrued || 0;
    }
}

export class Utente {
    id: string;
    name: string;
    last_name: string;
    email: string;
    telephone: string;
    type: TipoUtente;
    drinx: Map<string, ClubDrinxPoint>;
    id_stripe?: string;
    id_club?: string;
    password?: string;



    constructor({ id, name, last_name, email, type, telephone, drinx, id_stripe, id_club, password }: Partial<Utente>) {
        this.id = id || '';
        this.name = name || '';
        this.last_name = last_name || '';
        this.email = email || '';
        this.telephone = telephone || '';
        this.type = TipoUtente[type as keyof typeof TipoUtente];
        this.drinx = drinx || new Map<string, ClubDrinxPoint>();
        this.id_stripe = id_stripe;
        this.id_club = id_club;
        this.password = password;
    }

    public serializeUtente(): any {
        const result: any = { ...this };
        result.drinx = this.serializeDrinx();
        return result;
    }

    public serializeDrinx(): any {
        const result: any = {};
        this.drinx.forEach((value: ClubDrinxPoint, key: string) => {
            result[key] = { ...value }
        });
        return result;
    }
    public static createEmptyUtente(tipoUtente: TipoUtente, idClub?: string): Utente {
        const utente = new Utente({});
        utente.type = tipoUtente;
        if (idClub != undefined) utente.id_club = idClub;
        return utente;
    }
    public static utenteFromSnapshot(snapshot: DocumentSnapshot): Utente {
        const data = snapshot.data()!;
        return Utente.deserializeUtente(data);
    }
    static deserializeUtente(data: any): Utente {
        return new Utente({
            id: data.id,
            name: data.name,
            last_name: data.last_name,
            email: data.email,
            telephone: data.telephone,
            type: data.type,
            drinx: new Map(Object.entries(data.drinx ?? {})), // TODO fix
            id_stripe: data.id_stripe,
            id_club: data.id_club,
        });
    }

}
