import React, { useEffect, useState, useContext } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, Link } from "react-router-dom";
import "./Clubs.css";
import { auth, db, loadDbData } from "../utils/firebase";
import Header from "../utils/Header";
import AppContext from '../AppContext';
import Container from 'react-bootstrap/Container';
import { Row, Col, Table } from 'react-bootstrap';
import { Club } from "../models/ModelClub";
import { ClubModal } from "./ClubModal";



function Clubs() {
    const dbContext = useContext(AppContext);
    const [clubModal, setClubModal] = useState<Club | undefined>(undefined);

    const [user, loading, error] = useAuthState(auth);
    const [name, setName] = useState("name");
    const navigate = useNavigate();


    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/");

        if (!dbContext.firstRead) {
            loadDbData(dbContext);
            dbContext.setFirstRead(true);
        }
    }, [user, loading]);

    const tr: any = [];
    dbContext.clubs.map((club: Club) => {
        tr.push(<tr key={club.id}>
            <td><img src={club.image_url} key={club.image_url} className="clubInfoImgSmall" alt="Club Logo" hidden={!club?.image_url || club?.image_url == ""} />
            </td>
            <td className="link-tab-user"><Link to={"/clubs/" + club.id}>{club.name}</Link></td>
            <td className="content-tab-user">{club.social_reason}</td>
            <td className="content-tab-user">{club.email}</td>
            <td className="content-tab-user">{club.address.street}</td>
            <td className="content-tab-user">{club.promotions?.length}</td>
        </tr>);
    });
    return (
        <div>
            <Header />
            <Container fluid="md">
                <Row className="users-title vertical-padding" >
                    <Col style={{ textAlign: "left" }}>Tutti i Clubs</Col>
                    <Col xs lg="2">
                        <div className="primary-button" onClick={() => setClubModal(new Club({}))}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <path d="M15 9.75H9.75V15H8.25V9.75H3V8.25H8.25V3H9.75V8.25H15V9.75Z" fill="#C8236F" />
                            </svg>
                            Aggiungi
                        </div>

                    </Col>
                </Row>
                <Row>
                    <Table responsive style={{ verticalAlign: "middle" }}>
                        <thead className="header-tab-users">
                            <tr>
                                <th></th>
                                <th>Nome</th>
                                <th>Ragione sociale</th>
                                <th>Email</th>
                                <th>Indirizzo</th>
                                <th># Promozioni</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tr}
                        </tbody>
                    </Table>
                </Row>
            </Container>
            <ClubModal
                show={clubModal != undefined}
                onHide={() => {
                    setClubModal(undefined);
                }}
                club={clubModal}
                dbContext={dbContext}
            />
        </div>

    );
}

export default Clubs;
