import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import { Promotion } from '../models/ModelClub';
import { dateToMillisecond, millisecondsToDate } from '../utils/utilities';
import { savePromotion } from '../utils/firebase';
import { TipoPurchase } from '../models/ModelPurchase';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';
require("./PromotionModal.css");

export function PromotionModal(props: any) {
  const [promotion, setPromotion] = useState(props.promotion);

  function onInputChange(e: React.ChangeEvent<any>): void {
    const tmpPromotion = { ...promotion }; // Crea una copia di promotion
    tmpPromotion[e.target.name] = e.target.value; // Aggiorna la copia
    setPromotion(tmpPromotion);
  }

  function savePromotionToDB() {
    console.log("PREPARE TO SAVE PROMOTION ")
    const tmpPromotion = { ...promotion };
    if (tmpPromotion.type == undefined) tmpPromotion.type = TipoPurchase.PURCHASE;
    tmpPromotion.unit_price = tmpPromotion.type == TipoPurchase.PURCHASE ? parseInt(promotion.unit_price) * 100 : parseInt(promotion.unit_price);
    tmpPromotion.insertion_date = dateToMillisecond(promotion.insertion_date);
    tmpPromotion.ending_date = dateToMillisecond(promotion.ending_date);
    savePromotion(tmpPromotion, props.idClub, props.dbContext);
    console.log("SAVE PROMOTION CONCLUDED");
    props.onHide();
  }

  useEffect(() => {
    if (props.promotion != undefined) setPromotion(props.promotion);
  }, [props.promotion]);

  return (
    <Modal aria-labelledby="contained-modal-title-vcenter"
      {...props}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Aggiungi o modifica una promozione
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="grid-example">
        <Container>
          <Row className='riga-input-modal'>
            <div className='modal-label'>
              Nome
            </div>
            <div className='modal-field'>
              <input className="modal-input" name='name' value={promotion?.name} onChange={(e) => onInputChange(e)}></input>
            </div>
          </Row>
          <Row className='riga-input-modal'>
            <div className='modal-label'>
              Descrizione
            </div>
            <div className='modal-field'>
              <input className="modal-input" name='description' value={promotion?.description} onChange={(e) => onInputChange(e)}></input>
            </div>
          </Row>
          <Row className='riga-input-modal'>
            <div className='modal-label'>
              Tipo
            </div>
            <div className='modal-field'>
              <select className="modal-input" name="type" value={promotion?.type} onChange={e => onInputChange(e)} disabled={promotion?.has_purchases}>
                <option value={TipoPurchase.PURCHASE}>{TipoPurchase.PURCHASE}</option>
                <option value={TipoPurchase.REDEEM}>{TipoPurchase.REDEEM}</option>
              </select>
            </div>
          </Row>

          <Row className='riga-input-modal'>
            <div className='modal-label'>
              Prezzo
            </div>
            <div className='modal-field' style={{ flexDirection: 'row' }}>
              <input style={{ width: "80%" }} className="modal-input" type="number" step='0.01' name="unit_price" value={promotion?.unit_price} onChange={e => onInputChange(e)} disabled={promotion?.has_purchases}/>
              <span style={{ marginLeft: 10 }}>{promotion?.type == "REDEEM" ? "DRINX" : "€"}</span>
            </div>
          </Row>
          <Row className='riga-input-modal'>
            <div className='modal-label'>
              Data inizio
            </div>
            <div className='modal-field'>
              <input className="modal-input" type="datetime-local" name="insertion_date" value={promotion?.insertion_date} onChange={e => onInputChange(e)} />
            </div>
          </Row>
          <Row className='riga-input-modal'>
            <div className='modal-label'>
              Data fine
            </div>
            <div className='modal-field'>
              <input className="modal-input" type="datetime-local" name="ending_date" value={promotion?.ending_date} onChange={e => onInputChange(e)} />
            </div>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <div onClick={props.onHide} className="secondary-button">Annulla</div>
        <div onClick={() => savePromotionToDB()} className="primary-button">Salva</div>
      </Modal.Footer>
    </Modal>
  );
}
